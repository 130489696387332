.NavbarItems{
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .25);
    width: 95%;
    height: 80px;
    border-radius: 13px;
    /* lower code for fix width problem */
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    /* putting higher value coz want always see navbar at the top */
    z-index: 9999;
}
/* display flex for align item in a line  */
/*  */
/* .logo{
    width: 100%;
    height: 100%; 
  }
.logo img {
    width: 55%;
    height: 100%; 
    object-fit: cover; 
    margin-right: 200px;
  } */

.logo{
    width: 27%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo img{
    width: 82%;
}
.nav-menu{
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
}
.nav-links{
    text-decoration: none;
    color: #222;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.7rem 1rem;
    white-space: nowrap;

}
.nav-links i{
    padding-right: 10px;
}
.nav-links:hover{
    background-color: #01959a;
    color: #fff;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}

.nav-links-mobile{
    display: none;
}
/* when we change the size of display or increase the words then white space help not to go in next line */
button{
    padding: 0.5rem 1rem;
    white-space: nowrap;
    border-radius: 0.3rem;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;

}

button:hover{
    background-color: #151516;
    color: #fff;
}

/* Hide when screen is large */
.menu-icons{
    display: none;
}

/* Responsive */
@media screen and (max-width:850px){
    /* we were not able to see the logo and icon for this we will set navitem's z index at99 and nav-menu at -1 */
    .NavbarItems{
        z-index: 99;
    }
    .logo{
        width: 80%;
        
    }
    .nav-menu{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background: #fff;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        /* left: 0; */
        left: -100%;
        /* opacity: 1; */
        opacity: 0;
        align-items: stretch;
        padding: 80px 0 30px 0;
        margin: 0;
        /* z-index: -1; */
        transition: 0.3s ease-in-out;
        border-radius: 13px;

        
    }
    /* I want that when click on hamburger then only show nav menu for this I will create another class named active */
    /* in nav-menu, initially set left: 0 but change it -100% so that I can hide */
   
    .nav-menu.active{
        left:0;
        opacity: 1;
        z-index: -1;
        transition: 0.3s ease-in-out;
    }
   
    .nav-links{
        display: block;
        width: 100%;
        font-size: 1.2rem;
        padding: 2rem 0;

    }
    .nav-links:hover{
        background: #01959a;
        transition: none;
    }
    /* we will hide this button and another button working */
    button{
        display: none;
    }
    .nav-links-mobile{
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: auto;
        border-radius: 4px;
        width: 80%;
        background: #01959a;
        text-decoration: none;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: 2px;
    }
    .menu-icons{
        display: block;
        cursor: pointer;
    }
    .menu-icons i{
        font-size: 1.2rem;
        color: #222;
    }
}