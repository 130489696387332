@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100&family=Poppins:ital,wght@0,100;0,200;0,400;0,500;1,200;1,300;1,400&family=Ubuntu:wght@300&display=swap');


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;;
}
html{
  scroll-behavior: smooth;
}
.App{
  text-align: center;
}
